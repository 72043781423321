<template>
  <div>
    <Card />
  </div>
</template>

<script>
import Card from "./Card-comp.vue";
export default {
  data: () => ({
    dialog: false,
    dialog1: false,
  }),
  components: {
    Card,
  },
  methods: {},
};
</script>

<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>
