<template>
  <div>
    <Navbar />
    <v-main class="main">
      <v-container fluid>
        <Main />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Navbar from '../components/Navbar-comp.vue';
import Main from '../components/dashboard/Main-comp.vue';
export default {
components: {
    Navbar,
    Main,
},
  data: () => ({
  }),
}
</script>

<style>

</style>