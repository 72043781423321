<template>
  <div>
    <div class="header-information">
      <div class="box-information-page">
        <h3>Painéis BI</h3>
        <div class="indicator"></div>
      </div>

      <div class="box-btn">
        <v-btn
          v-show="btnVoltar"
          depressed
          text
          color="#34495e"
          @click="exibirTudo"
        >
          <v-icon size="16" class="mr-2" color="#34495e"
            >mdi-chevron-left</v-icon
          >
          Voltar
        </v-btn>

        <v-btn
          depressed
          :color="color"
          :loading="loadingFavs"
          @click="meusFavoritos"
          text
        >
          <v-icon size="16" class="mr-2" :color="color">mdi-star</v-icon>
          Favoritos
        </v-btn>

        <v-btn
          depressed
          color="#34495e"
          :loading="filterActive"
          @click="exibirFiltro = !exibirFiltro"
          text
        >
          <v-icon size="16" class="mr-2" color="#34495e">mdi-filter</v-icon>
          Filtros
        </v-btn>
      </div>
    </div>

    <v-expand-transition>
      <div class="filter-items" v-if="exibirFiltro">
        <div class="text-filter">
          <v-text-field
            class="mr-2"
            v-model="nomeBI"
            label="Nome do Painel"
            placeholder="Nome do painel (BI)"
            outlined
            dense
          ></v-text-field>

          <v-select
            class="mr-2"
            v-model="selectedsuperintendencia"
            :items="superintendencia"
            item-text="name"
            item-value="id"
            @change="atualizarDadosSelect(selectedsuperintendencia)"
            outlined
            dense
            label="Superintendência"
          ></v-select>
        </div>

        <div class="box-btn">
          <v-btn
            class="mr-2"
            depressed
            color="primary"
            :loading="loading"
            @click="atualizarDados"
            ><v-icon>mdi-magnify</v-icon></v-btn
          >
          <v-btn
            depressed
            color="#dfe6e9"
            :loading="loading"
            @click="limparCampos"
            ><v-icon>mdi-eraser-variant</v-icon></v-btn
          >
        </div>
      </div>
    </v-expand-transition>

    <v-row>
      <v-col cols="12" md="4" sm="12" v-for="dado in dados" :key="dado.id">
        <v-hover v-slot="{ hover }">
          <v-card class="mx-auto" color="grey lighten-4" max-width="600">
            <v-img
              v-if="dado.image != null"
              v-bind:src="urlIMG + dado.image"
              :aspect-ratio="16 / 9"
              @click="abrirModal(dado.link)"
            >
              <v-expand-transition>
                <div
                  @click="abrirModal(dado.link)"
                  v-if="hover"
                  class="d-flex transition-in--out primary darken-2 v-card--reveal white--text content-radius"
                  style="height: 100%"
                >
                  {{ dado.description }}
                </div>
              </v-expand-transition>
            </v-img>
            <v-img
              v-else
              @click="abrirModal(dado.link)"
              :aspect-ratio="16 / 9"
              src="../../assets/BG.png"
            >
              <v-expand-transition>
                <div
                  @click="abrirModal(dado.link)"
                  v-if="hover"
                  class="d-flex transition-in--out primary darken-2 v-card--reveal white--text content-radius"
                  style="height: 100%"
                >
                  {{ dado.description }}
                </div>
              </v-expand-transition>
            </v-img>
            <v-card-text class="pt-6 card-text" style="position: relative">
              <v-btn
                absolute
                color="#fdcb6e"
                class="white--text"
                fab
                right
                top
                v-if="verificarEstrela(dado.id)"
                @click="removeFavorito(dado.id)"
              >
                <v-icon> mdi-star </v-icon>
              </v-btn>

              <v-btn
                absolute
                color="gray"
                class="black--text"
                fab
                right
                top
                v-if="!verificarEstrela(dado.id)"
                @click="addFavorito(dado.id)"
              >
                <v-icon> mdi-star-outline </v-icon>
              </v-btn>
              <!-- <div class="font-weight-light grey--text text-h6 mb-2">
                For the perfect meal
              </div> -->
              <h4 class="black--text mb-2" @click="abrirModal(dado.link)">
                {{ dado.title }}
              </h4>
              <!-- <div class="font-weight-light text-h6 mb-2">
                Our Vintage kitchen utensils delight any chef.<br />
                Made of bamboo by hand
              </div> -->
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>

      <v-col cols="12" md="12" sm="12">
        <div class="text-center">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="inputPage"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <div class="navbar-iframe">
          <div class="dialog-bar">
            <v-btn text small @click="dialog = false"> Fechar </v-btn>
          </div>

          <div class="dialog-bar">
            <v-btn color="success" text small @click="reloadIfame">
              Atualizar
            </v-btn>
          </div>
        </div>

        <iframe
          id="iframeid"
          class="iframe"
          :src="url"
          frameborder="0"
          style="overflow: hidden; height: 100%; width: 100%"
          height="100%"
          width="100%"
        ></iframe>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "@/axios";

export default {
  data() {
    return {
      dialog: false,
      exibirFiltro: false,
      url: "",
      dados: [],
      superintendencia: [],
      selectedsuperintendencia: "",
      nomeBI: "",
      token: "",
      user: "",
      favs: [],
      page: 1,
      totalPages: 0,
      dadosTemporarios: [],
      // urlIMG: "http://desenv.portalti.saude.ba.gov.br/storage/dashboards/",
      // urlIMG: "http://localhost:8000/storage/dashboards/",
      // urlIMG: "http://homologa.bi-hgrs.saude.ba.gov.br/storage/dashboards/",
      urlIMG: "https://bi-hgrs.saude.ba.gov.br/storage/dashboards/",
      loading: false,
      filterActive: false,
      loadingFavs: false,
      color: "#34495e",
      btnVoltar: false,
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      axios // BUSCA USUÁRIO LOGADO
        .get(`/auth/me`)
        .then((response) => {
          this.user = response.data;
        });

      axios.get(`/favorities/dashboards`).then((response) => {
        let resp = response.data.data;
        resp.forEach((element) => {
          this.favs.push(element.id);
        });
      });

      axios // BUSCA DASHBOARDS
        .get(`/dashboards`)
        .then((response) => {
          this.dados = response.data.data;
          this.page = response.data.current_page;
          this.totalPages = response.data.last_page;
        });

      axios // BUSCA SUPERINTENDENCIAS
        .get(`/superintendencies`)
        .then((response) => {
          this.superintendencia = response.data.data;
        });
    },

    inputPage(event) {
      if (this.filterActive == true) {
        this.page = event;
        axios
          .post(`/dashboards/filter?page=${event}`, this.dadosTemporarios)
          .then((response) => {
            this.dados = response.data.data;
            this.page = response.data.current_page;
            this.totalPages = response.data.last_page;
          });
      } else {
        this.page = event;
        axios.get(`/dashboards?page=${event}`).then((response) => {
          this.dados = response.data.data;
          this.page = response.data.current_page;
          this.totalPages = response.data.last_page;
        });
      }
    },

    verificarEstrela(id) {
      if (this.favs.indexOf(id) != -1) {
        return true;
      }
      return false;
    },

    reloadIfame() {
      document.getElementById("iframeid").src += "";
    },

    atualizarDados() {
      var searchInfos = new Object();
      searchInfos.superintendence_id = this.selectedsuperintendencia;
      searchInfos.title = this.nomeBI;

      this.dadosTemporarios = searchInfos;
      this.filterActive = true;
      this.loadingFavs = true;
      this.loading = true;

      if (searchInfos.title == "" && searchInfos.title == "") {
        axios.get(`/dashboards`).then((response) => {
          this.loading = true;
          setTimeout(() => {
            this.dados = response.data.data;
            this.page = response.data.current_page;
            this.totalPages = response.data.last_page;
            this.filterActive = false;
            this.loadingFavs = false;
            this.loading = false;
          }, 1000);
        });
      } else {
        axios.post(`/dashboards/filter`, searchInfos).then((response) => {
          this.loading = true;
          setTimeout(() => {
            this.dados = response.data.data;
            this.page = response.data.current_page;
            this.totalPages = response.data.last_page;
            this.filterActive = false;
            this.loadingFavs = false;
            this.loading = false;
          }, 1000);
        });
      }
    },

    atualizarDadosSelect(value) {
      var searchInfos = new Object();
      searchInfos.superintendence_id = value;
      searchInfos.title = "";

      this.dadosTemporarios = searchInfos;
      this.filterActive = true;
      this.loadingFavs = true;
      this.loading = true;

      axios.post(`/dashboards/filter`, searchInfos).then((response) => {
        this.loading = true;
        setTimeout(() => {
          this.dados = response.data.data;
          this.page = response.data.current_page;
          this.totalPages = response.data.last_page;
          this.filterActive = false;
          this.loadingFavs = false;
          this.loading = false;
        }, 1000);
      });
    },

    limparCampos() {
      var searchInfos = new Object();
      searchInfos.superintendence_id = "";
      searchInfos.title = "";

      this.selectedsuperintendencia = "";
      this.nomeBI = "";

      this.filterActive = true;
      this.loadingFavs = true;
      this.loading = true;

      axios.get(`/dashboards`).then((response) => {
        this.loading = true;
        setTimeout(() => {
          this.dados = response.data.data;
          this.page = response.data.current_page;
          this.totalPages = response.data.last_page;
          this.filterActive = false;
          this.loadingFavs = false;
          this.loading = false;
        }, 1000);
      });
    },

    meusFavoritos() {
      this.loadingFavs = true;
      this.filterActive = true;
      this.loading = true;
      this.color = "#ffb703";

      axios.get(`/favorities/dashboards`).then((response) => {
        this.loading = true;
        setTimeout(() => {
          this.dados = response.data.data;
          this.loading = false;
          this.page = response.data.current_page;
          this.totalPages = response.data.last_page;
          this.btnVoltar = true;
          this.filterActive = false;
          this.loadingFavs = false;
        }, 1000);
      });
    },

    addFavorito(idDashboard) {
      var infos = new Object();
      infos.dashboard_id = idDashboard;
      infos.user_id = this.user.id;

      axios.post(`/favorities/dashboards`, infos).then((response) => {
        this.message = response.data.message;
        this.loading = true;
        setTimeout(() => {
          this.favs.push(idDashboard);
          this.loading = false;
        }, 1000);
      });
    },

    removeFavorito(idDashboard) {
      var infos = new Object();
      infos.dashboard_id = idDashboard;
      infos.user_id = this.user.id;

      axios.post(`/favorities/dashboards/delete`, infos).then((response) => {
        setTimeout(() => {
          this.message = response.data.message;
          this.favs.splice(this.favs.indexOf(idDashboard), 1);
        }, 1000);
      });
    },

    exibirTudo() {
      this.btnVoltar = false;
      this.loadingFavs = true;
      this.filterActive = true;
      this.loading = true;
      this.color = "#34495e";

      axios.get(`/dashboards`).then((response) => {
        setTimeout(() => {
          this.dados = response.data.data;
          this.page = response.data.current_page;
          this.totalPages = response.data.last_page;
          this.filterActive = false;
          this.loadingFavs = false;
          this.loading = false;
        }, 1000);
      });
    },

    abrirModal(item) {
      this.url = item;
      this.dialog = true;
    },
  },
};
</script>

<style scoped>
.header-information {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header-information > .box-information-page {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.header-information > .box-information-page > h3 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
}

.header-information > .box-information-page > .indicator {
  height: 2px;
  width: 50px;
  border-radius: 5px;
  margin-right: 10px;
  background: -webkit-linear-gradient(
    160deg,
    rgb(3, 69, 211) 0%,
    rgb(15, 162, 207) 100%
  );
}

.box-btn {
  display: flex;
  justify-content: center;
}

.filter-items {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}

.text-filter {
  display: flex;
}

/* Alterações nos cards e imgs */
.v-image__image.v-image__image--cover,
.content-radius {
  border-radius: 10px;
}

.v-card {
  border-radius: 15px !important;
}

.v-card {
  cursor: pointer;
}

.v-application .grey.lighten-4 {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px !important;
}

.card-text {
  min-height: 100px;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 1;
  position: absolute;
  width: 100%;

  padding: 10px;
  font-family: "Poppins";
  font-size: 12px;
}
/*  */

.navbar-iframe {
  display: flex;
}

.dialog-bar {
  width: 80px !important;
}

.fav {
  position: relative;
  top: -60px;
  left: 15px;
}

.card-chart {
  border: 1px solid salmon !important;
  border-radius: 8px !important;
}

.dialog-bar {
  width: 100%;
  background: #fafafa;
}

.card-info {
  display: flex;
  width: 70%;
  flex-direction: column;
}

.card-bi {
  min-height: 170px;
  border-radius: 10px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img-content {
  height: 130px;
  width: 130px;
  position: absolute;
  top: 15%;
  right: 0;
}

.v-card__title {
  font-family: "Poppins";
  font-size: 15px !important;
}

.wrapper {
  padding: 0px 20px;
  max-width: 250px;
}

.text-card {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: "Poppins";
  font-size: 14px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

@media only screen and (max-width: 1024px) {
  .img-content {
    height: 120px;
    width: 120px;
    position: absolute;
    top: 20%;
    right: 0;
  }
}
</style>
